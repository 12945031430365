import { ReactElement, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import cn from 'classnames';
import { toast } from 'react-toastify';

import { historyStateNamingForRunsOpenedFromWSP } from 'pages/runs/_ui/runs-table/constants';
import { selectCurrentUserRole, selectUserUniqueId } from 'app/store/authReducer';
import {
  selectCurrentXAxisPulsecounts,
  selectIsAssignmentProcessing,
  selectIsUnassignButtonLoading,
  selectMetaInfoAboutRunSegments,
  selectRunInfo,
  selectRunMetaData,
  updateCurrentRunAssigneeAndOrProgress,
} from 'app/store/viewerReducer';
import AllRoutes from 'shared/config/routes';
import SrButton from 'shared/components/SrButton';
import SrModal from 'shared/components/SrModal';
import { UrlIconSVG } from 'shared/svgs';
import SrTooltip from 'shared/components/SrTooltip';
import SrCircularProgress from 'shared/components/SrCircularProgress';
import { allUserRolesEnum } from 'shared/config/roles';
import ConfirmUnassignActionModal from './_ui/ConfirmUnassignActionModal';

import styles from './styles.module.scss';

const MAX_RUN_PROGRESS_BY_PULSECOUNT = 100;

function ViewerHeaderBts({ setCompleteRunProgressByPulsecount }): ReactElement {
  const [isConfirmUnassignActionModalOpened, setIsConfirmUnassignActionModalOpened] =
    useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { state }: any = useLocation();
  const { runId } = useParams();

  const runMetaData = useSelector(selectRunMetaData);
  const runInfo = useSelector(selectRunInfo);
  const currentUserRole = useSelector(selectCurrentUserRole);
  const userUniqueId = useSelector(selectUserUniqueId);
  const isUnassignButtonLoading = useSelector(selectIsUnassignButtonLoading);
  const isAssignmentProcessing = useSelector(selectIsAssignmentProcessing);
  const currentXAxisPulsecounts = useSelector(selectCurrentXAxisPulsecounts);
  const metaInfoAboutRunSegments = useSelector(selectMetaInfoAboutRunSegments);

  const copyUrl = (): void => {
    const urlT1kName = `${window.location.origin}/run/${runMetaData?.recording_name}/viewer${window.location.search}`;
    navigator.clipboard.writeText(urlT1kName).then(() => {
      toast.success('Link copied to clipboard', {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    });
  };

  const elmerPreprocessingFinished = runInfo?.preprocessing?.progress === 100;
  const isRunExpired = moment().subtract(72, 'hours').isAfter(moment.unix(runInfo?.created_date));
  const currentUserIsAllowedToAssignById =
    runInfo?.[currentUserRole]?.user_id === userUniqueId ||
    runInfo?.[currentUserRole]?.user_id === null;
  const technicalConditions =
    elmerPreprocessingFinished && !isRunExpired && currentUserIsAllowedToAssignById;

  const currentUserIsAlreadyAssigned = runInfo?.[currentUserRole]?.user_id === userUniqueId;
  const currentUserAssignedAsAnalyst =
    runInfo?.[allUserRolesEnum.ANALYST]?.user_id === userUniqueId;
  const currentUserAssignedAsAuditor =
    runInfo?.[allUserRolesEnum.AUDITOR]?.user_id === userUniqueId;
  const isAllAnalyzeBtnConditionsMetToAssign =
    currentUserRole === allUserRolesEnum.ANALYST &&
    !currentUserAssignedAsAuditor &&
    technicalConditions;
  const isAllAuditBtnConditionsMetToAssign =
    currentUserRole === allUserRolesEnum.AUDITOR &&
    !currentUserAssignedAsAnalyst &&
    technicalConditions;

  const getTitleForAssignBtn = (): string => {
    if (currentUserRole === allUserRolesEnum.ANALYST && !currentUserIsAlreadyAssigned) {
      return 'Analyze';
    }

    if (currentUserRole === allUserRolesEnum.AUDITOR && !currentUserIsAlreadyAssigned) {
      return 'Audit';
    }

    return 'Unassign';
  };

  useEffect(() => {
    // End pulsecount and/or stop pulsecount inside meta can not normally be 0
    if (currentXAxisPulsecounts.pulse_count_end && metaInfoAboutRunSegments.run_pulse_count_stop) {
      const completeRunProgressByPulsecountComputed =
        ((currentXAxisPulsecounts.pulse_count_end -
          metaInfoAboutRunSegments.run_pulse_count_start) /
          (metaInfoAboutRunSegments.run_pulse_count_stop -
            metaInfoAboutRunSegments.run_pulse_count_start)) *
        100;

      if (completeRunProgressByPulsecountComputed <= MAX_RUN_PROGRESS_BY_PULSECOUNT) {
        setCompleteRunProgressByPulsecount(completeRunProgressByPulsecountComputed);
      } else {
        setCompleteRunProgressByPulsecount(MAX_RUN_PROGRESS_BY_PULSECOUNT);
      }
    }
  }, [
    currentXAxisPulsecounts.pulse_count_start,
    currentXAxisPulsecounts.pulse_count_end,
    metaInfoAboutRunSegments.run_pulse_count_start,
    metaInfoAboutRunSegments.run_pulse_count_stop,
  ]);

  return (
    <div className={styles.ViewerHeaderBts__wrapper}>
      <div className={styles.ViewerHeaderBts__btnWrapper}>
        <SrTooltip
          title="Copy link"
          onClick={copyUrl}
          classNameForPopperProps={styles.ViewerHeaderBts__tooltipForCopyURL}
          placement="bottom"
          relativePlacement
        >
          <SrButton
            title={<UrlIconSVG />}
            customClassName={styles.ViewerHeaderBts__btnIconWrapper}
            onClick={copyUrl}
          />
        </SrTooltip>
      </div>
      {(currentUserRole === allUserRolesEnum.ANALYST ||
        currentUserRole === allUserRolesEnum.AUDITOR) &&
        runInfo?.[currentUserRole]?.user_id !== undefined && (
          <div className={styles.ViewerHeaderBts__btnUnassignBlock}>
            <div className={styles.ViewerHeaderBts__btnUnassignWrapper}>
              {(isUnassignButtonLoading || isAssignmentProcessing) && <SrCircularProgress />}
              <SrButton
                disabled={
                  (currentUserRole === allUserRolesEnum.ANALYST &&
                    !isAllAnalyzeBtnConditionsMetToAssign) ||
                  (currentUserRole === allUserRolesEnum.AUDITOR &&
                    !isAllAuditBtnConditionsMetToAssign) ||
                  runInfo?.[currentUserRole]?.progress === 100
                }
                customClassName={cn(styles.ViewerHeaderBts__assignBtn, {
                  [styles.ViewerHeaderBts__unassignBtn]:
                    runInfo?.[currentUserRole]?.user_id === userUniqueId,
                  [styles.ViewerHeaderBts__analyzeOrAuditBtn]:
                    runInfo?.[currentUserRole]?.user_id !== userUniqueId,
                })}
                onClick={() => {
                  if (currentUserAssignedAsAnalyst || currentUserAssignedAsAuditor) {
                    setIsConfirmUnassignActionModalOpened(true);
                  } else {
                    dispatch(
                      updateCurrentRunAssigneeAndOrProgress({
                        runId,
                        currentUserRole,
                        personAssignedId: userUniqueId,
                        progressValue: 1,
                        additionalActionAfterSuccess: () => {
                          // eslint-disable-next-line no-restricted-globals
                          location.reload();
                        },
                      })
                    );
                  }
                }}
                title={getTitleForAssignBtn()}
              />
            </div>
          </div>
        )}
      {isConfirmUnassignActionModalOpened && (
        <SrModal
          title="Unassign"
          onClose={() => {
            setIsConfirmUnassignActionModalOpened(false);
          }}
          withCancelButton
          withAcceptButton
          onCancel={() => setIsConfirmUnassignActionModalOpened(false)}
          onAccept={() => {
            dispatch(
              updateCurrentRunAssigneeAndOrProgress({
                runId,
                currentUserRole,
                personAssignedId: null,
                progressValue: 0,
                additionalActionAfterSuccess: () => {
                  if (state && state[historyStateNamingForRunsOpenedFromWSP]) {
                    navigate(-1);
                  } else {
                    navigate(AllRoutes.RUNS);
                  }
                },
              })
            );
            setIsConfirmUnassignActionModalOpened(false);
          }}
          cancelBtnTitle="Cancel"
          acceptBtnTitle="Unassign"
        >
          <ConfirmUnassignActionModal />
        </SrModal>
      )}
    </div>
  );
}

export default ViewerHeaderBts;
