import { ReactElement, useState } from 'react';
import { Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';

import { historyStateNamingForRunsOpenedFromWSP } from 'pages/runs/_ui/runs-table/constants';
import {
  selectRunMetaData,
  selectIsVisionImagesOn,
  setIsVisionImagesOn,
  selectRunInfo,
  selectIsRunInfoUpdating,
  selectIsStaticRecognitionBoxesDisplayed,
  setIsStaticRecognitionBoxesDisplayed,
} from 'app/store/viewerReducer';
import { selectCurrentSelectedCustomerId } from 'app/store/libraryTrackLocationsReducer';
import AllRoutes from 'shared/config/routes';
import SrTooltip from 'shared/components/SrTooltip';
import SrButton from 'shared/components/SrButton';
import LogoSVG from 'shared/svgs/LogoSVG';
import { CameraIconSVG, NoCameraIconSVG, ViewerBackArrowBtnSVG } from 'shared/svgs';
import { selectAllUserInfoFromCognito } from 'app/store/authReducer';
import ProgressByPulsecountBar from './_ui/ProgressByPulsecountBar';
import ViewerHeaderBts from './_ui/ViewerHeaderBtns';
import ProfileContent from './_ui/ProfileContent';

import styles from './styles.module.scss';

function Header(): ReactElement {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state }: any = useLocation();

  const allUserInfoFromCognito = useSelector(selectAllUserInfoFromCognito);
  const runMetaData = useSelector(selectRunMetaData);
  const isVisionImagesOn = useSelector(selectIsVisionImagesOn);
  const runInfo = useSelector(selectRunInfo);
  const isRunInfoUpdating = useSelector(selectIsRunInfoUpdating);
  const currentSelectedCustomerId = useSelector(selectCurrentSelectedCustomerId);
  const isStaticRecognitionBoxesDisplayed = useSelector(selectIsStaticRecognitionBoxesDisplayed);

  const [completeRunProgressByPulsecount, setCompleteRunProgressByPulsecount] = useState(0);

  return (
    <header className={styles.Header__container}>
      <div className={styles.Header__leftBlockWrapper}>
        <LogoSVG additionalClassName={styles.Header__logo} />
        <Routes>
          <Route
            path={AllRoutes.RUNS_RUNID_VIEWER}
            element={
              <SrTooltip
                title={`Run passed by ${Math.round(completeRunProgressByPulsecount || 0)}%`}
              >
                <div className={styles.Header__positionInRunBlockWrapper}>
                  <div className={styles.Header__positionInRunLabelText}>Position in run</div>
                  <ProgressByPulsecountBar
                    completeRunProgressByPulsecount={completeRunProgressByPulsecount}
                  />
                </div>
              </SrTooltip>
            }
          />
        </Routes>
      </div>
      <Routes>
        <Route
          path={AllRoutes.RUNS_RUNID_VIEWER}
          element={
            <>
              <Button
                classes={{
                  root: styles.Header__viewerBackBtnWrapper,
                  disabled: styles.Header__viewerBackBtnWrapperDisabled,
                }}
                disabled={isRunInfoUpdating || currentSelectedCustomerId !== runInfo?.company_id}
                onClick={() => {
                  if (state && state[historyStateNamingForRunsOpenedFromWSP]) {
                    navigate(-1);
                  } else {
                    navigate(AllRoutes.RUNS);
                  }
                }}
              >
                <ViewerBackArrowBtnSVG />
              </Button>
              <div className={styles.Header__viewerPart}>
                <div className={styles.Header__btnDisableStaticBoxWrapper}>
                  <SrButton
                    title={`${isStaticRecognitionBoxesDisplayed ? 'Hide' : 'Show'} boxes`}
                    customClassName={styles.Header__btnHideBoxButton}
                    onClick={() =>
                      dispatch(
                        setIsStaticRecognitionBoxesDisplayed(!isStaticRecognitionBoxesDisplayed)
                      )
                    }
                  />
                </div>
                <div className={styles.Header__btnSwitchImgWrapper}>
                  <SrTooltip
                    title={
                      isVisionImagesOn
                        ? 'Switch off all vision images'
                        : 'Switch on all vision images'
                    }
                    onClick={() => dispatch(setIsVisionImagesOn(!isVisionImagesOn))}
                    classNameForPopperProps={styles.Header__tooltipForVisionImagesBtn}
                    placement="bottom"
                    relativePlacement
                  >
                    <SrButton
                      title={isVisionImagesOn ? <NoCameraIconSVG /> : <CameraIconSVG />}
                      customClassName={styles.Header__btnIconWrapper}
                      onClick={() => dispatch(setIsVisionImagesOn(!isVisionImagesOn))}
                    />
                  </SrTooltip>
                </div>

                {/* TODO just now -> change to real time */}
                <div className={styles.Header__viewerRecording}>{runMetaData?.recording_name}</div>
                <ViewerHeaderBts
                  setCompleteRunProgressByPulsecount={setCompleteRunProgressByPulsecount}
                />
                {/*  TODO redo later upon requirements */}
                {/* <div className={styles.Header__viewerSaveTime}>
                  {t('header.viewer_header.last_save_info')}
                </div> */}
              </div>
            </>
          }
        />
      </Routes>
      <div className={styles.Header__userWrapper}>
        {allUserInfoFromCognito && <ProfileContent />}
      </div>
    </header>
  );
}

export default Header;
