import { ReactNode, ReactElement, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useResizeDetector } from 'react-resize-detector';

import { selectIsScreenshotLoading } from 'app/store/reportsReducer';
import SrCircularProgress from 'shared/components/SrCircularProgress';
import Header from './_ui/Header';

import styles from './styles.module.scss';

interface IProps {
  children: ReactNode;
}

const ZULA_VIEWER_HEIGHT = 1200;

function Layout({ children }: IProps): ReactElement {
  const isScreenshotLoading = useSelector(selectIsScreenshotLoading);

  const { height: layoutWrapperHeight = 0, ref: layoutWrapperRef } = useResizeDetector<any>({
    refreshMode: 'debounce',
    refreshRate: 50,
  });

  useEffect(() => {
    if (layoutWrapperRef.current) {
      const zoomScale = window.innerHeight / ZULA_VIEWER_HEIGHT;
      layoutWrapperRef.current.style.zoom = `${zoomScale * 100}%`;
      document.documentElement.style.setProperty('--zoom-scale', `${zoomScale}`);
    }
  }, [layoutWrapperHeight, layoutWrapperRef]);

  return (
    <div ref={layoutWrapperRef} className={styles.Layout__wrapper}>
      {isScreenshotLoading && (
        <SrCircularProgress
          additionalWrapperClassName={styles.Layout__loader}
          text="Please, wait for Bscan images to be downloaded. Do not open other tabs while waiting."
        />
      )}
      <Header />
      <div className={styles.Layout__inner}>{children}</div>
    </div>
  );
}

export default Layout;
