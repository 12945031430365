/* eslint-disable camelcase */
/* eslint-disable func-names */
import { createSlice, Dispatch } from '@reduxjs/toolkit';
import isEqual from 'lodash.isequal';
import { toast } from 'react-toastify';
import { nanoid } from 'nanoid';
import moment from 'moment';

import {
  CustomerCodesInterface,
  DEFAULT_INDUCTION_CHART_SCALE,
  RecognitionInterface,
  SEGMENT_LENGTH,
  generatedFromRecognitionTypes,
  movableSidebarOpenedTabValues,
} from 'pages/runs/[runId]/viewer/constants';

import { axiosInstance } from 'shared/api/http-common';
import { EMPTY_OBJECT } from 'shared/utils';
import dexieDb, { EdRecognitionsDexieDb, RecognitionsDexieItem } from 'app/db/dexieDb';
import { writeLoadedSegmentsAroundRecognition } from 'shared/utils/dexieUtils';
import { fetchGetTscanMatchedDataSourcesByRunId, setCurrentRunDateTime } from './tscanReducer';
import {
  INITIAL_SELECTED_TRACK_LOCATIONS,
  setCurrentSelectedCustomerId,
  setSelectedTrackLocations,
} from './libraryTrackLocationsReducer';
import { DEFAULT_SEGMENT_LIMIT_BACKGROUND_FETCH_MAP } from './constants/segments';

export const DEFAULT_META_INFO_ABOUT_RUN_SEGMENTS = {
  run_pulse_count_start: 0,
  run_pulse_count_stop: 0,
  segment_length: SEGMENT_LENGTH,
  total_run_segments: 0,
};

export const DEFAULT_CURRENT_X_AXIS_PULSE_COUNTS = { pulse_count_start: 0, pulse_count_end: 0 };
export const DEFAULT_DISPLAYED_PULSECOUNTS_ON_THE_VIEWER = 2000;
export const WARNING_RECOGNITION_TEXT_UIC_CODE = 'Chief Operator Comment_e_9001';
export const WARNING_RECOGNITION_START_COMMENT = 'Start Test';
export const WARNING_RECOGNITION_END_COMMENT = 'Test stopped';

const DEFAULT_BSCAN_RAIL_DATA_EMPTY = {
  visionImages: [],
  ultrasoundHits: [],
  inductionCurve: [],
};

export interface TraclLocationsUnsavedChanges {
  operator: boolean;
  trackLocationsAmount: boolean;
  trackLocationsValues: boolean;
}
export const DEFAULT_TRACK_LOCATIONS_UNSAVED_CHANGES = {
  operator: false,
  trackLocationsAmount: false,
  trackLocationsValues: false,
};

export interface MouseCoordinates {
  x: number | null;
  y: number | null;
}

export interface CommentOption {
  value: string;
  label: string;
}

const INITIAL_VIEWER_STATE = {
  metaInfoAboutRunSegments: DEFAULT_META_INFO_ABOUT_RUN_SEGMENTS,
  metaInfoAboutRunSegmentsError: null,
  currentXAxisPulsecounts: DEFAULT_CURRENT_X_AXIS_PULSE_COUNTS,
  displayedPulseCountsOnTheViewer: DEFAULT_DISPLAYED_PULSECOUNTS_ON_THE_VIEWER,
  runSegmentsError: null,
  isRunSegmentsLoading: false,
  isRunMapLoading: false,
  runMap: [],
  runMapError: null,
  isCustomerCodesLoading: false,
  customerCodes: [],
  customerCodesError: null,
  isCustomerCodesLoaded: false,
  customerCodesObj: {},
  isRecognitionsListLoading: false,
  isAllRecognitionsDisplayedOnRecognitionsList: false,
  recognitionsListError: null,
  isRecognitionsListLoaded: false,
  isRecognitionsListLoadedAndSavedToDexieDb: false,
  // null - init, true - loaded and saved well, false - loaaded and saved error
  recognitionsListLoadedAndSavedWithCustomerCodesData: null,
  // for correct change handler using useLiveQuery from dexie-db (it doesn't provide from box)
  recognitionsListDexieDbUpdater: 0,
  currentSelectedRecognition: {},
  topRailData: {
    visionImages: [],
    ultrasoundHits: [],
    inductionCurve: [],
  },
  bottomRailData: {
    visionImages: [],
    ultrasoundHits: [],
    inductionCurve: [],
  },
  runMetaData: {},
  runMetaDataError: null,
  isRunMetaDataLoading: false,
  runInfo: null,
  isRunInfoLoading: false,
  isRunInfoUpdating: false,
  isAssignmentProcessing: false,
  isCompleteReviewButtonLoading: false,
  isUnassignButtonLoading: false,
  runUxChannels: [],
  isRunUxChannelsLoading: false,
  isVisionImagesOn: true,
  currentOpenedTabMovableSidebar: movableSidebarOpenedTabValues.TRACK_LOCATION,
  isRunClipped: false,
  isMovableSidebarOpen: false,
  isTrackLocationUnsavedChanges: DEFAULT_TRACK_LOCATIONS_UNSAVED_CHANGES,
  isTrackLocationUnsavedChangesWindowOpened: false,
  customerInfo: null,
  inductionChartScale: DEFAULT_INDUCTION_CHART_SCALE,
  inductionZoomInValue: 0,
  inductionZoomOutValue: 0,
  segmentsRequestId: 0,
  currentTopMouseCoordinates: {
    x: null,
    y: null,
  },
  currentBottomMouseCoordinates: {
    x: null,
    y: null,
  },
  isSegmentsForRecognitionLoading: false,
  isStaticRecognitionBoxesDisplayed: true,
};

export const viewerSlice = createSlice({
  name: 'viewer',
  initialState: INITIAL_VIEWER_STATE,
  reducers: {
    setIsRunSegmentsLoading: (state, action) => {
      state.isRunSegmentsLoading = action.payload;
    },
    setRunSegmentsError: (state, action) => {
      state.runSegmentsError = action.payload;
    },
    setTopRailData: (state, action) => {
      state.topRailData = action.payload;
    },
    setBottomRailData: (state, action) => {
      state.bottomRailData = action.payload;
    },
    setMetaInfoAboutRunSegments: (state, action) => {
      state.metaInfoAboutRunSegments = action.payload;
    },
    setMetaInfoAboutRunSegmentsError: (state, action) => {
      state.metaInfoAboutRunSegmentsError = action.payload;
    },
    setCurrentXAxisPulsecounts: (state, action) => {
      state.currentXAxisPulsecounts = action.payload;
    },
    setIsRunMapLoading: (state, action) => {
      state.isRunMapLoading = action.payload;
    },
    setRunMap: (state, action) => {
      state.runMap = action.payload;
    },
    setRunMapError: (state, action) => {
      state.runMapError = action.payload;
    },
    setIsCustomerCodesLoading: (state, action) => {
      state.isCustomerCodesLoading = action.payload;
    },
    setCustomerCodes: (state, action) => {
      state.customerCodes = action.payload;
    },
    setCustomerCodesError: (state, action) => {
      state.customerCodesError = action.payload;
    },
    setIsCustomerCodesLoaded: (state, action) => {
      state.isCustomerCodesLoaded = action.payload;
    },
    setCustomerCodesObj: (state, action) => {
      state.customerCodesObj = action.payload;
    },
    setIsRecognitionsListLoading: (state, action) => {
      state.isRecognitionsListLoading = action.payload;
    },
    setRecognitionsListError: (state, action) => {
      state.recognitionsListError = action.payload;
    },
    setIsRecognitionsListLoaded: (state, action) => {
      state.isRecognitionsListLoaded = action.payload;
    },
    setIsRecognitionsListLoadedAndSavedToDexieDb: (state, action) => {
      state.isRecognitionsListLoadedAndSavedToDexieDb = action.payload;
    },
    setRecognitionsListLoadedAndSavedWithCustomerCodesData: (state, action) => {
      state.recognitionsListLoadedAndSavedWithCustomerCodesData = action.payload;
    },
    increaseRecognitionsListDexieDbUpdater: (state) => {
      state.recognitionsListDexieDbUpdater += 1;
    },
    setDisplayedPulseCountsOnTheViewer: (state, action) => {
      state.displayedPulseCountsOnTheViewer = action.payload;
    },
    setIsAllRecognitionsDisplayedOnRecognitionsList: (state, action) => {
      state.isAllRecognitionsDisplayedOnRecognitionsList = action.payload;
    },
    setCurrentSelectedRecognition: (state, action) => {
      state.currentSelectedRecognition = action.payload;
    },
    setRunMetaData: (state, action) => {
      state.runMetaData = action.payload;
    },
    setRunMetaDataError: (state, action) => {
      state.runMetaDataError = action.payload;
    },
    setIsRunMetaDataLoading: (state, action) => {
      state.isRunMetaDataLoading = action.payload;
    },
    setRunInfo: (state, action) => {
      state.runInfo = action.payload;
    },
    setIsRunInfoLoading: (state, action) => {
      state.isRunInfoLoading = action.payload;
    },
    setIsRunInfoUpdating: (state, action) => {
      state.isRunInfoUpdating = action.payload;
    },
    setIsAssignmentProcessing: (state, action) => {
      state.isAssignmentProcessing = action.payload;
    },
    setIsCompleteReviewButtonLoading: (state, action) => {
      state.isCompleteReviewButtonLoading = action.payload;
    },
    setIsUnassignButtonLoading: (state, action) => {
      state.isUnassignButtonLoading = action.payload;
    },
    setRunUxChannels: (state, action) => {
      state.runUxChannels = action.payload;
    },
    setIsRunUxChannelsLoading: (state, action) => {
      state.isRunUxChannelsLoading = action.payload;
    },
    setIsVisionImagesOn: (state, action) => {
      state.isVisionImagesOn = action.payload;
    },
    setCurrentOpenedTabMovableSidebar: (state, action) => {
      state.currentOpenedTabMovableSidebar = action.payload;
    },
    setIsRunClipped: (state, action) => {
      state.isRunClipped = action.payload;
    },
    setIsMovableSidebarOpen: (state, action) => {
      state.isMovableSidebarOpen = action.payload;
    },
    setIsTrackLocationUnsavedChanges: (state, action) => {
      state.isTrackLocationUnsavedChanges = action.payload;
    },
    setIsTrackLocationUnsavedChangesWindowOpened: (state, action) => {
      state.isTrackLocationUnsavedChangesWindowOpened = action.payload;
    },
    setCustomerInfo: (state, action) => {
      state.customerInfo = action.payload;
    },
    setInductionChartScale: (state, action) => {
      state.inductionChartScale = action.payload;
    },
    setInductionZoomInValue: (state, action) => {
      state.inductionZoomInValue = action.payload;
    },
    setInductionZoomOutValue: (state, action) => {
      state.inductionZoomOutValue = action.payload;
    },
    setSegmentsRequestId: (state, action) => {
      state.segmentsRequestId = action.payload;
    },
    setCurrentMouseTopCoordinates: (state, action) => {
      state.currentTopMouseCoordinates.x = action.payload.x;
      state.currentTopMouseCoordinates.y = action.payload.y;
    },
    setCurrentMouseBottomCoordinates: (state, action) => {
      state.currentBottomMouseCoordinates.x = action.payload.x;
      state.currentBottomMouseCoordinates.y = action.payload.y;
    },
    setIsSegmentsForRecognitionLoading: (state, action) => {
      state.isSegmentsForRecognitionLoading = action.payload;
    },
    setIsStaticRecognitionBoxesDisplayed: (state, action) => {
      state.isStaticRecognitionBoxesDisplayed = action.payload;
    },
    resetViewerToInitialState: (state) => ({ ...INITIAL_VIEWER_STATE, runInfo: state.runInfo }),
  },
});

export const {
  setIsRunSegmentsLoading,
  setRunSegmentsError,
  setTopRailData,
  setBottomRailData,
  setMetaInfoAboutRunSegments,
  setMetaInfoAboutRunSegmentsError,
  setCurrentXAxisPulsecounts,
  setIsRunMapLoading,
  setRunMap,
  setRunMapError,
  setIsCustomerCodesLoading,
  setCustomerCodes,
  setCustomerCodesError,
  setIsCustomerCodesLoaded,
  setCustomerCodesObj,
  setIsRecognitionsListLoading,
  setRecognitionsListError,
  setIsRecognitionsListLoaded,
  setIsRecognitionsListLoadedAndSavedToDexieDb,
  setRecognitionsListLoadedAndSavedWithCustomerCodesData,
  setDisplayedPulseCountsOnTheViewer,
  setIsAllRecognitionsDisplayedOnRecognitionsList,
  setCurrentSelectedRecognition,
  setRunMetaData,
  setRunMetaDataError,
  setIsRunMetaDataLoading,
  resetViewerToInitialState,
  setRunInfo,
  setIsRunInfoLoading,
  setIsRunInfoUpdating,
  setIsAssignmentProcessing,
  setIsCompleteReviewButtonLoading,
  setIsUnassignButtonLoading,
  setRunUxChannels,
  setIsRunUxChannelsLoading,
  setIsVisionImagesOn,
  setCurrentOpenedTabMovableSidebar,
  setIsRunClipped,
  increaseRecognitionsListDexieDbUpdater,
  setIsMovableSidebarOpen,
  setIsTrackLocationUnsavedChanges,
  setIsTrackLocationUnsavedChangesWindowOpened,
  setCustomerInfo,
  setInductionChartScale,
  setInductionZoomInValue,
  setInductionZoomOutValue,
  setSegmentsRequestId,
  setCurrentMouseTopCoordinates,
  setCurrentMouseBottomCoordinates,
  setIsSegmentsForRecognitionLoading,
  setIsStaticRecognitionBoxesDisplayed,
} = viewerSlice.actions;

interface SegmentsQueryParams {
  segment_start: number;
  limit: number;
}

interface SegmentsMetaInfo {
  run_pulse_count_start: number;
  run_pulse_count_stop: number;
  segment_length: number;
  total_run_segments: number;
}

export const fetchRunSegments = function ({
  runId,
  pulsecountFromSearchParams,
  queryParams,
  latestSegmentReqId,
}: {
  runId: string | number;
  pulsecountFromSearchParams?: number | null;
  queryParams?: SegmentsQueryParams;
  latestSegmentReqId?: number;
}) {
  return async (dispatch, getState) => {
    const state = getState();
    const { metaInfoAboutRunSegments, currentXAxisPulsecounts } = state.viewer;

    dispatch(setIsRunSegmentsLoading(true));

    let metaInfoAboutRunSegmentsComputed: SegmentsMetaInfo = { ...metaInfoAboutRunSegments };

    if (isEqual(metaInfoAboutRunSegmentsComputed, DEFAULT_META_INFO_ABOUT_RUN_SEGMENTS)) {
      try {
        const metaInfoAboutRunSegmentsResponse: any = await axiosInstance.get(
          `runs/${runId}/segments/meta-data`
        );

        if (isEqual(metaInfoAboutRunSegmentsResponse.data, EMPTY_OBJECT)) {
          dispatch(setMetaInfoAboutRunSegmentsError(metaInfoAboutRunSegmentsResponse.data));
        } else {
          dispatch(setMetaInfoAboutRunSegmentsError(null));
        }

        const {
          run_pulse_count_start = 0,
          run_pulse_count_stop = 0,
          segment_length = SEGMENT_LENGTH,
          total_run_segments,
        } = metaInfoAboutRunSegmentsResponse.data;

        metaInfoAboutRunSegmentsComputed = {
          run_pulse_count_start,
          run_pulse_count_stop,
          segment_length,
          total_run_segments,
        };
      } catch (err) {
        dispatch(setMetaInfoAboutRunSegmentsError(err));
        toast.error(
          'Data fetching meta data about run failed, please update the page. If the problem occurs again - please contact the administrator'
        );
      }
    }

    let segment_start = Math.floor(
      metaInfoAboutRunSegmentsComputed.run_pulse_count_start / SEGMENT_LENGTH
    );
    // + 1 because we made Math.floor above, and we need cover full range in pulsecounts
    let segment_limit = DEFAULT_DISPLAYED_PULSECOUNTS_ON_THE_VIEWER / SEGMENT_LENGTH + 1;
    let currentXAxisPulsecounts_pulse_count_start =
      metaInfoAboutRunSegmentsComputed.run_pulse_count_start -
      DEFAULT_DISPLAYED_PULSECOUNTS_ON_THE_VIEWER / 2;
    let currentXAxisPulsecounts_pulse_count_end =
      metaInfoAboutRunSegmentsComputed.run_pulse_count_start +
      DEFAULT_DISPLAYED_PULSECOUNTS_ON_THE_VIEWER / 2;

    // validation for valid values for pulsecountFromSearchParams
    if (pulsecountFromSearchParams !== null && pulsecountFromSearchParams !== undefined) {
      if (
        pulsecountFromSearchParams >= metaInfoAboutRunSegmentsComputed.run_pulse_count_start &&
        pulsecountFromSearchParams <= metaInfoAboutRunSegmentsComputed.run_pulse_count_stop
      ) {
        segment_start = Math.floor(
          (pulsecountFromSearchParams - DEFAULT_DISPLAYED_PULSECOUNTS_ON_THE_VIEWER / 2) /
            SEGMENT_LENGTH
        );
        currentXAxisPulsecounts_pulse_count_start =
          pulsecountFromSearchParams - DEFAULT_DISPLAYED_PULSECOUNTS_ON_THE_VIEWER / 2;
        currentXAxisPulsecounts_pulse_count_end =
          currentXAxisPulsecounts_pulse_count_start + DEFAULT_DISPLAYED_PULSECOUNTS_ON_THE_VIEWER;
      } else if (
        pulsecountFromSearchParams > metaInfoAboutRunSegmentsComputed.run_pulse_count_stop
      ) {
        segment_start = Math.floor(
          (metaInfoAboutRunSegmentsComputed.run_pulse_count_stop -
            DEFAULT_DISPLAYED_PULSECOUNTS_ON_THE_VIEWER / 2) /
            SEGMENT_LENGTH
        );
        currentXAxisPulsecounts_pulse_count_end =
          metaInfoAboutRunSegmentsComputed.run_pulse_count_stop +
          DEFAULT_DISPLAYED_PULSECOUNTS_ON_THE_VIEWER / 2;

        currentXAxisPulsecounts_pulse_count_start =
          currentXAxisPulsecounts_pulse_count_end - DEFAULT_DISPLAYED_PULSECOUNTS_ON_THE_VIEWER;
      }
    } else if (queryParams) {
      segment_start = queryParams.segment_start;
      segment_limit = queryParams.limit;
    }

    if (segment_start < 0) {
      segment_start = 0;
    }

    try {
      const runSegmentsResponse: any = await axiosInstance.get(`runs/${runId}/segments`, {
        params: {
          segment_start,
          limit: segment_limit,
        },
      });

      if (isEqual(runSegmentsResponse.data, EMPTY_OBJECT)) {
        dispatch(setMetaInfoAboutRunSegmentsError(runSegmentsResponse.data));
      } else {
        dispatch(setRunSegmentsError(null));
      }

      const bscanData_rail_0_computed: any = {
        inductionCurve: [],
        ultrasoundHits: [],
        visionImages: [],
      };
      const bscanData_rail_1_computed: any = {
        inductionCurve: [],
        ultrasoundHits: [],
        visionImages: [],
      };

      const runSegmentsForDexieDb = runSegmentsResponse.data?.map((run_segment: any, index) => {
        if (isEqual(run_segment, EMPTY_OBJECT)) {
          return {
            segmentNumber: segment_start + index,
            bscanData_rail_0: DEFAULT_BSCAN_RAIL_DATA_EMPTY,
            bscanData_rail_1: DEFAULT_BSCAN_RAIL_DATA_EMPTY,
          };
        }

        const bscanData_rail_0 = {
          visionImages: run_segment.rail_0.vision_images.map((item) => ({
            ...item,
            id: item.id || nanoid(),
          })),
          ultrasoundHits: run_segment.rail_0.ultrasound_hits,
          inductionCurve: run_segment.rail_0.induction_curve,
        };
        const bscanData_rail_1 = {
          visionImages: run_segment.rail_1.vision_images.map((item) => ({
            ...item,
            id: item.id || nanoid(),
          })),
          ultrasoundHits: run_segment.rail_1.ultrasound_hits,
          inductionCurve: run_segment.rail_1.induction_curve,
        };

        bscanData_rail_0_computed.inductionCurve.push(...bscanData_rail_0.inductionCurve);
        bscanData_rail_0_computed.ultrasoundHits.push(...bscanData_rail_0.ultrasoundHits);
        bscanData_rail_0_computed.visionImages.push(...bscanData_rail_0.visionImages);

        bscanData_rail_1_computed.inductionCurve.push(...bscanData_rail_1.inductionCurve);
        bscanData_rail_1_computed.ultrasoundHits.push(...bscanData_rail_1.ultrasoundHits);
        bscanData_rail_1_computed.visionImages.push(...bscanData_rail_1.visionImages);

        return {
          segmentNumber: Math.floor(run_segment.pulse_count_start / SEGMENT_LENGTH),
          bscanData_rail_0,
          bscanData_rail_1,
        };
      });

      try {
        await dexieDb.segments.bulkPut(runSegmentsForDexieDb);
      } catch (err) {
        console.log('error in writing segments to IndexedDb, err: ', err);
      }

      if (!isEqual(metaInfoAboutRunSegmentsComputed, metaInfoAboutRunSegments)) {
        dispatch(setMetaInfoAboutRunSegments(metaInfoAboutRunSegmentsComputed));
      }

      if (latestSegmentReqId && latestSegmentReqId >= 0) {
        const { segmentsRequestId } = getState().viewer;

        if (segmentsRequestId === latestSegmentReqId) {
          dispatch(setBottomRailData(bscanData_rail_0_computed));
          dispatch(setTopRailData(bscanData_rail_1_computed));
        }
      } else {
        dispatch(setBottomRailData(bscanData_rail_0_computed));
        dispatch(setTopRailData(bscanData_rail_1_computed));
      }

      if (isEqual(currentXAxisPulsecounts, DEFAULT_CURRENT_X_AXIS_PULSE_COUNTS)) {
        dispatch(
          setCurrentXAxisPulsecounts({
            pulse_count_start: currentXAxisPulsecounts_pulse_count_start,
            pulse_count_end: currentXAxisPulsecounts_pulse_count_end,
          })
        );
      }
    } catch (err) {
      dispatch(setRunSegmentsError(err));
      toast.error(
        'Data fetching failed, please update the page. If the problem occurs again - please contact the administrator'
      );
    }

    dispatch(setIsRunSegmentsLoading(false));
  };
};

// recursion for map data
const fetchRunMapAllDataRecursion = ({
  runId,
  offset = 0,
  limit = 20000,
  runMapData = [],
  dispatch,
}: any): Promise<any> =>
  axiosInstance
    .get(`runs/${runId}/map`, {
      params: { offset, limit },
    })
    .then((runMapResponse) => {
      if (runMapResponse.status === 200) {
        // TODO: remove mapData adapter, we'll can remove this adapter when BE send to FE .map_data inside the response
        const mapData = runMapResponse.data?.map_data || runMapResponse.data || [];
        runMapData.push(...mapData);

        if (mapData.length < limit) {
          return runMapData;
        }

        return fetchRunMapAllDataRecursion({
          runId,
          offset: offset + limit,
          runMapData,
        });
      }

      let errorMessage = 'unknown error message in fetching map data for the Run';
      if (runMapResponse.status === 202 || runMapResponse.status === 404) {
        try {
          errorMessage = JSON.parse(runMapResponse.data?.body)?.message;
        } catch (err) {
          console.log('status code for map is 202 or 404 but JSON.parse.data failed: ', err);
        }
        return toast.error(errorMessage);
      }

      return toast.error(errorMessage);
    })
    .catch((error) => {
      dispatch(setRunMapError(error));
    });

export const fetchRunMap = function ({ runId }: any) {
  return async (dispatch) => {
    dispatch(setIsRunMapLoading(true));
    dispatch(setRunMapError(null));

    try {
      const runMapFinalData = await fetchRunMapAllDataRecursion({
        runId,
        dispatch,
      });

      if (Array.isArray(runMapFinalData) && runMapFinalData.length) {
        dispatch(setRunMapError(null));
        dispatch(setRunMap(runMapFinalData));
      } else {
        dispatch(setRunMapError(runMapFinalData));
      }
    } catch {
      toast.error(
        'Data fetching for map data, please reload the page to try loading the map data again. If the problem occurs again - please contact the administrator'
      );
    }
    dispatch(setIsRunMapLoading(false));
  };
};

export const fetchCustomerCodes = function ({ customerId }: any) {
  return async (dispatch) => {
    dispatch(setIsCustomerCodesLoading(true));
    try {
      const customerCodesResponse = await axiosInstance.get(`customers/${customerId}/codes`);

      if (isEqual(customerCodesResponse.data, EMPTY_OBJECT)) {
        dispatch(setCustomerCodesError(customerCodesResponse.data));
      }

      if (customerCodesResponse.data) {
        // WP4-1461 uic_code is not unique from the BE API. this is for filter identical uic_codes using rules from the wp4-1461 jira-task
        const customerCodes = customerCodesResponse.data;
        const customerCodesObj = {};
        customerCodes.forEach((customerCode) => {
          if (!customerCodesObj[customerCode.text_uic_code]) {
            customerCodesObj[customerCode.text_uic_code] = customerCode;
          } else if (
            customerCodesObj[customerCode.text_uic_code]?.system === 'user' &&
            customerCode.system === 'elmer'
          ) {
            customerCodesObj[customerCode.text_uic_code] = customerCode;
          }
        });

        dispatch(setCustomerCodes(Object.values(customerCodesObj)));
        dispatch(setCustomerCodesObj(customerCodesObj));
      } else {
        dispatch(setCustomerCodesError(customerCodesResponse));
      }
    } catch (err) {
      dispatch(setCustomerCodesError(err));
      toast.error(
        'Data fetching for Customer codes failed, please reload the page to try loading the Customer codes data again. If the problem occurs again - please contact the administrator'
      );
    }
    dispatch(setIsCustomerCodesLoading(false));
    dispatch(setIsCustomerCodesLoaded(true));
  };
};

export const fetchRunMetaData = function ({ runId }: any) {
  return async (dispatch) => {
    dispatch(setIsRunMetaDataLoading(true));
    try {
      const runMetaDataResponse: any = await axiosInstance.get(`runs/${runId}/meta-data`);

      const runMetaDataResponseData = Array.isArray(runMetaDataResponse.data)
        ? runMetaDataResponse.data[0]
        : runMetaDataResponse.data;

      if (runMetaDataResponseData) {
        dispatch(setRunMetaData(runMetaDataResponseData));

        const selectedTrackLocationsValidated = Array.isArray(
          runMetaDataResponseData?.selected_track_location
        )
          ? runMetaDataResponseData?.selected_track_location
          : [];

        dispatch(setSelectedTrackLocations(selectedTrackLocationsValidated));
        setRunMetaDataError(null);
      } else {
        setRunMetaDataError(runMetaDataResponseData);

        dispatch(setSelectedTrackLocations(INITIAL_SELECTED_TRACK_LOCATIONS));
      }

      dispatch(setCurrentRunDateTime(moment(runMetaDataResponseData.date).unix()));
    } catch (err) {
      dispatch(setSelectedTrackLocations(INITIAL_SELECTED_TRACK_LOCATIONS));
      dispatch(setRunMetaDataError(err));
      toast.error(
        'Data fetching for Run meta info failed, please reload the page to try loading the Run meta info again. If the problem occurs again - please contact the administrator'
      );
    }
    dispatch(fetchGetTscanMatchedDataSourcesByRunId({ runId }));
    dispatch(setIsRunMetaDataLoading(false));
  };
};

// recursion for recognitions
const fetchRecognitionsListAllDataRecursion = ({
  runId,
  nextPageKey = '',
  recognitionListData = [],
  dispatch,
}: any): Promise<any> =>
  axiosInstance
    .get(`runs/${runId}/recognitions`, {
      // 1500 is optimal size, default limit value: 500
      params: { start_key: nextPageKey, limit: 1500 },
    })
    .then((recognitionsListResponse) => {
      recognitionListData.push(...recognitionsListResponse.data.data);

      if (recognitionsListResponse.data.pagination.nextPageKey === null) {
        return recognitionListData;
      }

      return fetchRecognitionsListAllDataRecursion({
        runId,
        nextPageKey: recognitionsListResponse.data.pagination.nextPageKey,
        recognitionListData,
      });
    })
    .catch((error) => {
      dispatch(setRecognitionsListError(error));
    });

export const fetchRecognitionsList = function ({ runId }) {
  return async (dispatch) => {
    dispatch(setIsRecognitionsListLoading(true));
    dispatch(setRecognitionsListError(null));

    try {
      const recognitionsListFinalData = await fetchRecognitionsListAllDataRecursion({
        runId,
        dispatch,
      });
      dispatch(setIsRecognitionsListLoaded(true));

      // warning code
      let isStartTestTopRailRecognitionFound = false;
      let isStartTestBottomRailRecognitionFound = false;
      let isEndTestTopRailRecognitionFound = false;
      let isEndTestBottomRailRecognitionFound = false;

      const recognitionsListForDexieDb: RecognitionsDexieItem[] = [];
      const edRecognitiionsForDexieDb: EdRecognitionsDexieDb[] = [];
      recognitionsListFinalData.forEach((recognition: RecognitionInterface) => {
        if (recognition.generated_from === generatedFromRecognitionTypes.ENHANCED_DETECTION) {
          edRecognitiionsForDexieDb.push({
            recognition_id: recognition.recognition_id || nanoid(),
            rail_id: recognition.rail_id,
            pulse_count_start: recognition.pulse_count_start,
            pulse_count_end: recognition.pulse_count_end,
            recognition_obj: recognition,
          });
        } else {
          recognitionsListForDexieDb.push({
            recognition_id: recognition.recognition_id || nanoid(),
            rail_id: recognition.rail_id,
            sperry_id: recognition.sperry_id || '',
            milepost: recognition.milepost,
            pulse_count: recognition.pulse_count,
            analysis_stop: 0,
            audit_stop: 0,
            feature: 1,
            flaw: 0,
            suspect: recognition.suspect ? 1 : 0,
            historic: recognition.historic ? 1 : 0,
            icon_id: -1,
            recognition_obj: recognition,
          });
        }

        // warning code
        if (
          recognition.text_uic_code === WARNING_RECOGNITION_TEXT_UIC_CODE &&
          recognition.comment
            .toLowerCase()
            .includes(WARNING_RECOGNITION_START_COMMENT.toLowerCase()) &&
          recognition.rail_id === 1
        ) {
          isStartTestTopRailRecognitionFound = true;
        }

        if (
          recognition.text_uic_code === WARNING_RECOGNITION_TEXT_UIC_CODE &&
          recognition.comment
            .toLowerCase()
            .includes(WARNING_RECOGNITION_START_COMMENT.toLowerCase()) &&
          recognition.rail_id === 0
        ) {
          isStartTestBottomRailRecognitionFound = true;
        }

        if (
          recognition.text_uic_code === WARNING_RECOGNITION_TEXT_UIC_CODE &&
          recognition.comment
            .toLowerCase()
            .includes(WARNING_RECOGNITION_END_COMMENT.toLowerCase()) &&
          recognition.rail_id === 1
        ) {
          isEndTestTopRailRecognitionFound = true;
        }

        if (
          recognition.text_uic_code === WARNING_RECOGNITION_TEXT_UIC_CODE &&
          recognition.comment
            .toLowerCase()
            .includes(WARNING_RECOGNITION_END_COMMENT.toLowerCase()) &&
          recognition.rail_id === 0
        ) {
          isEndTestBottomRailRecognitionFound = true;
        }
      });

      if (
        !isStartTestTopRailRecognitionFound &&
        !isStartTestBottomRailRecognitionFound &&
        !isEndTestTopRailRecognitionFound &&
        !isEndTestBottomRailRecognitionFound
      ) {
        dispatch(setIsRunClipped(true));
      }

      if (recognitionsListForDexieDb.length) {
        try {
          const isSuccess = await dexieDb.recognitions.bulkAdd(recognitionsListForDexieDb);
          if (isSuccess) {
            dispatch(increaseRecognitionsListDexieDbUpdater());
            dispatch(setIsRecognitionsListLoadedAndSavedToDexieDb(true));
          } else {
            toast.error('error in writing recognitionsList to indexedDb');
          }
        } catch (err) {
          toast.error('error in writing recognitionsList to indexedDb');
        }
      } else {
        dispatch(setIsRecognitionsListLoadedAndSavedToDexieDb(true));
      }
      if (edRecognitiionsForDexieDb.length) {
        try {
          const isSuccess = await dexieDb.edRecognitions.bulkAdd(edRecognitiionsForDexieDb);
          console.log('edRecognitions parsed: ', isSuccess);
        } catch (err) {
          toast.error('error in writing edRecognitions to indexedDb');
        }
      }
    } catch {
      toast.error(
        'Data fetching for Recognitions list failed, please reload the page to try loading the Recognitions list again. If the problem occurs again - please contact the administrator'
      );
    }

    dispatch(setIsRecognitionsListLoading(false));
  };
};

export const updateCurrentRunAssigneeAndOrProgress =
  ({
    runId,
    personAssignedId,
    currentUserRole,
    progressValue,
    runRecordingName = 'no name',
    additionalActionAfterSuccess,
  }) =>
  async (dispatch) => {
    if (personAssignedId) {
      dispatch(setIsAssignmentProcessing(true));
      dispatch(setIsCompleteReviewButtonLoading(true));
    } else {
      dispatch(setIsUnassignButtonLoading(true));
    }
    try {
      const response = await axiosInstance.patch('runs', [
        {
          id: runId,
          [currentUserRole]: personAssignedId,
          [`${currentUserRole}_progress`]: progressValue,
        },
      ]);
      if (response.data) {
        if (progressValue === 100) {
          toast.success(`Success to complete run ${runRecordingName}`);
        } else if (personAssignedId === null) {
          toast.success('Success to unassign from a run');
        } else {
          toast.success('Success to assign on a run');
        }

        if (additionalActionAfterSuccess) additionalActionAfterSuccess();
      } else if (progressValue === 100) {
        toast.error(`Fail to complete run ${runRecordingName}`);
      } else if (personAssignedId === null) {
        toast.error('Fail to unassign from a run');
      } else {
        toast.error('Fail to assign on a run');
      }
    } catch (err) {
      if (personAssignedId && progressValue < 1) {
        toast.error('Error to assign on a run');
      } else if (personAssignedId === null) {
        toast.error('Error to unassign on a run');
      } else {
        toast.error(`Error to complete run ${runRecordingName}`);
      }
    }
    if (personAssignedId) {
      dispatch(setIsAssignmentProcessing(false));
      dispatch(setIsCompleteReviewButtonLoading(false));
    } else {
      dispatch(setIsUnassignButtonLoading(false));
    }
  };

export const fetchCustomerConfig = (customerId: string) => async (dispatch: Dispatch<any>) => {
  try {
    if (customerId && customerId !== 'UNKNOWN') {
      const response = await axiosInstance.get(`/customers/${customerId}`);
      if (response.data && response.data.length) {
        dispatch(setCustomerInfo(response.data[0]));
      } else {
        toast.warn('No customer config info.');
      }
    } else {
      toast.warn('No customer id to fetch customer data.');
    }
  } catch (err) {
    toast.error('Error while loading customer config.');
    dispatch(setCustomerInfo(null));
  }
};

export const getRunInfoAsync =
  ({ runId }) =>
  async (dispatch) => {
    dispatch(setIsRunInfoLoading(true));
    try {
      const response = await axiosInstance.get(`runs/${runId}`);
      if (response.data) {
        const currentlyOpenedRunData = Array.isArray(response.data)
          ? response.data[0]
          : response.data;
        dispatch(setRunInfo(currentlyOpenedRunData));
        dispatch(setCurrentSelectedCustomerId(currentlyOpenedRunData?.company_id));
        dispatch(fetchCustomerCodes({ customerId: currentlyOpenedRunData?.company_id }));
        dispatch(fetchCustomerConfig(currentlyOpenedRunData?.company_id));
      } else {
        toast.error(`Fail to load run ${runId}`);
        dispatch(fetchCustomerCodes({ customerId: 'error_in_runs_run_id_response' }));
      }
    } catch (err) {
      toast.error(`Error to load run ${runId}`);
      dispatch(fetchCustomerCodes({ customerId: 'error_in_runs_run_id_request' }));
    }
    dispatch(setIsRunInfoLoading(false));
  };

export const patchUpdateCompanyIdInRunInfo =
  ({ runId, company_id }) =>
  async (dispatch) => {
    dispatch(setIsRunInfoUpdating(true));
    try {
      const response = await axiosInstance.patch('runs', [
        {
          id: runId,
          company_id,
        },
      ]);
      if (response.data) {
        toast.success('Railroad successfully changed for this Run, page will be updated');
        if (window.location.search.includes('pulsecount')) {
          // eslint-disable-next-line no-restricted-globals
          location.reload();
        }
      } else {
        toast.error("Fail to update Run's company id (Railroad)");
      }
    } catch (err) {
      toast.error("Fail to update Run's company id (Railroad)");
    }
    dispatch(setIsRunInfoUpdating(false));
  };

export const getRunUxChannelsAsync =
  ({ runId, currentXAxisPulsecountsCenter }) =>
  async (dispatch) => {
    dispatch(setIsRunUxChannelsLoading(true));
    try {
      // TODO check required bode and obtained fields upn endpoint readiness
      const response = await axiosInstance.get(`runs/${runId}/ux-channels`, {
        params: { pulsecount: currentXAxisPulsecountsCenter },
      });
      if (response.data) {
        if (Array.isArray(response.data)) {
          dispatch(setRunUxChannels(response.data));
        }
      } else {
        toast.error(`Failed to load run ${runId} ux channels`);
      }
    } catch (err) {
      toast.error(`Error to load run ${runId} ux channels`);
    }
    dispatch(setIsRunUxChannelsLoading(false));
  };

export const fetchSegmentsByRecognitionId = function ({ runId, recognition_id, pulse_count }: any) {
  return async (_dispatch, getState) => {
    const { displayedPulseCountsOnTheViewer } = getState().viewer;
    const isSegmentsByRecognitionAlreadyDownloaded =
      await dexieDb.loadedSegmentsAroundRecognitionId.get(recognition_id);

    if (!isSegmentsByRecognitionAlreadyDownloaded) {
      let segmentStartToLoad =
        Math.floor(pulse_count / SEGMENT_LENGTH) -
        DEFAULT_SEGMENT_LIMIT_BACKGROUND_FETCH_MAP[displayedPulseCountsOnTheViewer] / 2;
      if (segmentStartToLoad < 0) {
        segmentStartToLoad = 0;
      }
      const segmentsInDexieDb = await dexieDb.segments
        .where('segmentNumber')
        .between(
          segmentStartToLoad,
          segmentStartToLoad +
            DEFAULT_SEGMENT_LIMIT_BACKGROUND_FETCH_MAP[displayedPulseCountsOnTheViewer]
        )
        .toArray();

      if (
        segmentsInDexieDb.length ===
        DEFAULT_SEGMENT_LIMIT_BACKGROUND_FETCH_MAP[displayedPulseCountsOnTheViewer]
      ) {
        dexieDb.loadedSegmentsAroundRecognitionId.add({
          recognition_id,
        });
      } else {
        try {
          const runSegmentsResponse: any = await axiosInstance.get(`runs/${runId}/segments`, {
            params: {
              segment_start: segmentStartToLoad,
              limit: DEFAULT_SEGMENT_LIMIT_BACKGROUND_FETCH_MAP[displayedPulseCountsOnTheViewer],
            },
          });

          const loadedSegments: any = [];

          runSegmentsResponse.data?.forEach((run_segment: any, index: number) => {
            if (isEqual(run_segment, EMPTY_OBJECT)) {
              loadedSegments.push({
                segmentNumber: segmentStartToLoad + index,
                bscanData_rail_0: DEFAULT_BSCAN_RAIL_DATA_EMPTY,
                bscanData_rail_1: DEFAULT_BSCAN_RAIL_DATA_EMPTY,
              });
              return;
            }
            loadedSegments.push({
              segmentNumber: Math.floor(run_segment.pulse_count_start / SEGMENT_LENGTH),
              bscanData_rail_0: {
                visionImages: run_segment.rail_0.vision_images,
                ultrasoundHits: run_segment.rail_0.ultrasound_hits,
                inductionCurve: run_segment.rail_0.induction_curve,
              },
              bscanData_rail_1: {
                visionImages: run_segment.rail_1.vision_images,
                ultrasoundHits: run_segment.rail_1.ultrasound_hits,
                inductionCurve: run_segment.rail_1.induction_curve,
              },
            });
          });

          writeLoadedSegmentsAroundRecognition(recognition_id);

          try {
            dexieDb.segments.bulkPut(loadedSegments);
          } catch (err) {
            console.log('error in writing segments to IndexedDb err: ', err);
          }
        } catch (err) {
          toast.error('Data segments fetching failed for fetchSegmentsByRecognitionId');
        }
      }
    }
  };
};

export const fetchSegmentsBySegmentStart = function ({ runId, segmentStart, limit }: any) {
  return async (dispatch) => {
    dispatch(setIsSegmentsForRecognitionLoading(true));

    try {
      const runSegmentsResponse: any = await axiosInstance.get(`runs/${runId}/segments`, {
        params: {
          segment_start: segmentStart,
          limit,
        },
      });

      const loadedSegments: any = [];

      runSegmentsResponse.data?.forEach((run_segment: any, index: number) => {
        if (isEqual(run_segment, EMPTY_OBJECT)) {
          loadedSegments.push({
            segmentNumber: segmentStart + index,
            bscanData_rail_0: DEFAULT_BSCAN_RAIL_DATA_EMPTY,
            bscanData_rail_1: DEFAULT_BSCAN_RAIL_DATA_EMPTY,
          });
          return;
        }
        loadedSegments.push({
          segmentNumber: Math.floor(run_segment.pulse_count_start / SEGMENT_LENGTH),
          bscanData_rail_0: {
            visionImages: run_segment.rail_0.vision_images,
            ultrasoundHits: run_segment.rail_0.ultrasound_hits,
            inductionCurve: run_segment.rail_0.induction_curve,
          },
          bscanData_rail_1: {
            visionImages: run_segment.rail_1.vision_images,
            ultrasoundHits: run_segment.rail_1.ultrasound_hits,
            inductionCurve: run_segment.rail_1.induction_curve,
          },
        });
      });

      try {
        dexieDb.segments.bulkPut(loadedSegments);
      } catch (err) {
        console.log('error in writing segments to IndexedDb err: ', err);
      }
    } catch (err) {
      toast.error('Data segments fetching failed for fetchSegmentsByRecognitionId');
    }

    dispatch(setIsSegmentsForRecognitionLoading(false));
  };
};

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selectRunMetaData = (state): any => state.viewer.runMetaData;
export const selectRunMetaDataError = (state): any => state.viewer.runMetaDataError;
export const selectIsRunMetaDataLoading = (state): boolean => state.viewer.isRunMetaDataLoading;
export const selectIsRunSegmentsLoading = (state): boolean => state.viewer.isRunSegmentsLoading;
export const selectRunSegmentsError = (state): any => state.viewer.runSegmentsError;
export const selectTopRailData = (state): any => state.viewer.topRailData;
export const selectBottomRailData = (state): any => state.viewer.bottomRailData;
export const selectMetaInfoAboutRunSegments = (state): any => state.viewer.metaInfoAboutRunSegments;
export const selectMetaInfoAboutRunSegmentsError = (state): any =>
  state.viewer.metaInfoAboutRunSegmentsError;
export const selectCurrentXAxisPulsecounts = (
  state
): { pulse_count_start: number; pulse_count_end: number } => state.viewer.currentXAxisPulsecounts;
export const selectIsRunMapLoading = (state): boolean => state.viewer.isRunMapLoading;
export const selectRunMap = (state): any => state.viewer.runMap;
export const selectRunMapError = (state): any => state.viewer.runMapError;
export const selectCustomerCodes = (state): CustomerCodesInterface[] => state.viewer.customerCodes;
export const selectCustomerCodesError = (state): any => state.viewer.customerCodesError;
export const selectIsCustomerCodesLoaded = (state): boolean => state.viewer.isCustomerCodesLoaded;
export const selectCustomerCodesObj = (state): any => state.viewer.customerCodesObj;
export const selectIsCustomerCodesLoading = (state): boolean => state.viewer.isCustomerCodesLoading;
export const selectRecognitionsListError = (state): any => state.viewer.recognitionsListError;
export const selectIsRecognitionsListLoading = (state): boolean =>
  state.viewer.isRecognitionsListLoading;
export const selectIsRecognitionsListLoaded = (state): boolean =>
  state.viewer.isRecognitionsListLoaded;
export const selectIsRecognitionsListLoadedAndSavedToDexieDb = (state): boolean =>
  state.viewer.isRecognitionsListLoadedAndSavedToDexieDb;
export const selectRecognitionsListLoadedAndSavedWithCustomerCodesData = (state): null | boolean =>
  state.viewer.recognitionsListLoadedAndSavedWithCustomerCodesData;
export const selectRecognitionsListDexieDbUpdater = (state): boolean =>
  state.viewer.recognitionsListDexieDbUpdater;
export const selectDisplayedPulseCountsOnTheViewer = (state): number =>
  state.viewer.displayedPulseCountsOnTheViewer;
export const selectIsAllRecognitionsDisplayedOnRecognitionsList = (state): boolean =>
  state.viewer.isAllRecognitionsDisplayedOnRecognitionsList;
export const selectCurrentSelectedRecognition = (state): any =>
  state.viewer.currentSelectedRecognition;
export const selectRunInfo = (state): any => state.viewer.runInfo;
export const selectIsRunInfoLoading = (state): boolean => state.viewer.isRunInfoLoading;
export const selectIsRunInfoUpdating = (state): boolean => state.viewer.isRunInfoUpdating;
export const selectIsAssignmentProcessing = (state): boolean => state.viewer.isAssignmentProcessing;
export const selectIsCompleteReviewButtonLoading = (state): boolean =>
  state.viewer.isCompleteReviewButtonLoading;
export const selectIsUnassignButtonLoading = (state): boolean =>
  state.viewer.isUnassignButtonLoading;
export const selectRunUxChannels = (state): any => state.viewer.runUxChannels;
export const selectIsRunUxChannelsLoading = (state): boolean => state.viewer.isRunUxChannelsLoading;
export const selectIsVisionImagesOn = (state): boolean => state.viewer.isVisionImagesOn;
export const selectCurrentOpenedTabMovableSidebar = (state): number | null =>
  state.viewer.currentOpenedTabMovableSidebar;
export const selectIsRunClipped = (state): boolean => state.viewer.isRunClipped;
export const selectIsMovableSidebarOpen = (state): boolean => state.viewer.isMovableSidebarOpen;
export const selectIsTrackLocationUnsavedChanges = (state): TraclLocationsUnsavedChanges =>
  state.viewer.isTrackLocationUnsavedChanges;
export const selectIsTrackLocationUnsavedChangesWindowOpened = (state): boolean =>
  state.viewer.isTrackLocationUnsavedChangesWindowOpened;
export const selectUseMetricValue = (state): boolean => state.viewer.customerInfo?.use_metric;
export const selectUnknownTrackLocationsComments = (state): string[] =>
  state.viewer.customerInfo?.track_locations?.comments_for_unknown_options || [];
export const selectUntestedTrackLocationsComments = (state): string[] =>
  state.viewer.customerInfo?.track_locations?.comments_for_untested_options || [];
export const selectInductionChartScale = (state): number[] => state.viewer.inductionChartScale;
export const selectInductionZoomInValue = (state): number => state.viewer.inductionZoomInValue;
export const selectInductionZoomOutValue = (state): number => state.viewer.inductionZoomOutValue;
export const selectCurrentMouseBottomCoordinates = (state): MouseCoordinates =>
  state.viewer.currentBottomMouseCoordinates;
export const selectCurrentMouseTopCoordinates = (state): MouseCoordinates =>
  state.viewer.currentTopMouseCoordinates;
export const selectIsSegmentsForRecognitionLoading = (state): boolean =>
  state.viewer.isSegmentsForRecognitionLoading;
export const selectIsStaticRecognitionBoxesDisplayed = (state): boolean =>
  state.viewer.isStaticRecognitionBoxesDisplayed;

export default viewerSlice.reducer;
