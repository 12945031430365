import Dexie from 'dexie';
import { nanoid } from 'nanoid';

export interface Segments {
  segmentNumber: number;
  bscanData_rail_0: any;
  bscanData_rail_1: any;
}

export interface RecognitionsDexieItem {
  recognition_id: number | string;
  rail_id: 0 | 1;
  milepost: number;
  pulse_count: number;
  historic: 0 | 1;
  suspect: 0 | 1;
  flaw: 0 | 1;
  feature: 0 | 1;
  icon_id: number;
  analysis_stop: 0 | 1;
  audit_stop: 0 | 1;
  recognition_obj: any;
  customer_code_obj?: any;
  sperry_id: string;
}

export interface LoadedSegmentsAroundRecognitionId {
  recognition_id: number | string;
}

export interface TscanRunsDexieDb {
  age: number;
  current_start_pulse_count: number;
  current_end_pulse_count: number;
  matched_recognitions: number;
  original_run_id: string;
  original_run_name: string;
  same_direction: 0 | 1;
  running_mode: string;
}

export interface TscanRunsRecognitionsDexieDb {
  current_pulse_count: number;
  recognition_id: number | string;
  current_rail_id: 0 | 1;
  original_run_id: string;
  original_run_name: string;
  original_run_age: number;
  same_direction: 0 | 1;
  recognition_obj: any;
  customer_code_obj?: any;
}

export interface EdRecognitionsDexieDb {
  recognition_id: number | string;
  rail_id: 0 | 1;
  pulse_count_start: number;
  pulse_count_end: number;
  recognition_obj: any;
}

export interface TscanRunsEdRecognitionsDexieDb {
  current_pulse_count_start: number;
  current_pulse_count_end: number;
  recognition_id: number | string;
  current_rail_id: 0 | 1;
  original_run_id: string;
  original_run_name: string;
  original_run_age: number;
  same_direction: 0 | 1;
  recognition_obj: any;
}

// Define your Dexie database class
class DexieDb extends Dexie {
  segments!: Dexie.Table<Segments>;

  loadedSegmentsAroundRecognitionId!: Dexie.Table<LoadedSegmentsAroundRecognitionId>;

  recognitions!: Dexie.Table<RecognitionsDexieItem>;

  tscanRuns!: Dexie.Table<TscanRunsDexieDb>;

  tscanRunsRecognitions!: Dexie.Table<TscanRunsRecognitionsDexieDb>;

  edRecognitions!: Dexie.Table<EdRecognitionsDexieDb>;

  tscanRunsEdRecognitions!: Dexie.Table<TscanRunsEdRecognitionsDexieDb>;

  constructor(dexieDbId: string) {
    super(dexieDbId);
    this.version(5).stores({
      segments: 'segmentNumber',
      loadedSegmentsAroundRecognitionId: 'recognition_id',
      recognitions:
        '[pulse_count+recognition_id], recognition_id, rail_id, milepost, pulse_count, historic, suspect, flaw, feature, icon_id, analysis_stop, audit_stop',
      tscanRuns:
        '[age+original_run_id], original_run_id, age, current_start_pulse_count, current_end_pulse_count',
      tscanRunsRecognitions:
        '[current_pulse_count+recognition_id], recognition_id, current_rail_id, current_pulse_count, original_run_id',
      edRecognitions:
        '[pulse_count_start+recognition_id], recognition_id, rail_id, pulse_count_start, pulse_count_end',
      tscanRunsEdRecognitions:
        '[current_pulse_count_start+recognition_id], recognition_id, current_rail_id, current_pulse_count_start, current_pulse_count_end, original_run_id',
    });
  }
}

const maximumAmountOfDbsForDomain = 40;
const dexieDbNames = Dexie.getDatabaseNames();

dexieDbNames
  .then((dbNames) => {
    if (dbNames.length > maximumAmountOfDbsForDomain) {
      dbNames.forEach((name) => {
        Dexie.delete(name).then(() => {
          console.log(`DeixeDb with id:"${name}" deleted`);
        });
      });
    }
  })
  .catch((error) => {
    console.error('error in deleting or getting db names:', error);
  });

const uniqueIdForOpenedViewer = nanoid();
const dexieDb = new DexieDb(uniqueIdForOpenedViewer);
window.addEventListener('beforeunload', () => {
  Dexie.delete(uniqueIdForOpenedViewer);
});

// Export the database instance
export default dexieDb;
